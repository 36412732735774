<template>
  <div class="exercise-list-page w-100">
    <basic-subheader title="">
      <template v-slot:actions>
        <b-dropdown
          id="dropdown-right dropdown-form"
          class="dropdown-form-filter mr-2"
          no-caret
          right
        >
          <template #button-content>
            <span class="svg-icon">
              <inline-svg src="/media/svg/icons/Neolex/Basic/filter.svg" />
            </span>
            Bộ lọc
          </template>
          <h6 class="d-flex align-items-center mb-0 p-4">
            <span class="svg-icon mr-3">
              <inline-svg src="/media/svg/icons/Neolex/Basic/filter.svg" />
            </span>
            Bộ lọc
          </h6>
          <b-dropdown-divider> </b-dropdown-divider>
          <b-dropdown-form class="mw-400">
            <b-container class="p-0">
              <b-row>
                <b-col>
                  <basic-input
                    label="Mã"
                    placeholder="Nhập mã cảm xúc"
                    name="codeParams"
                    :value.sync="filter.code"
                  ></basic-input>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <basic-input
                    label="Tên cảm xúc "
                    placeholder="Nhập tên cảm xúc "
                    name="codeParams"
                    :value.sync="filter.name"
                  ></basic-input>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <basic-select
                    label="Nhóm cảm xúc"
                    placeholder="--- Chọn nhóm cảm xúc ---"
                    name="wards"
                    :options="optionTypeEmotions"
                    :value.sync="filter.emotionCategoryId"
                    :solid="false"
                    :allowEmpty="true"
                    track-by="value"
                  />
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-checkbox-group
                    v-model="filter.isActive"
                    :options="[{ text: 'Lọc Inactive', value: true }]"
                  ></b-form-checkbox-group>
                </b-col>
              </b-row>
            </b-container>
          </b-dropdown-form>
          <b-dropdown-divider> </b-dropdown-divider>
          <div class="d-flex align-items-center justify-content-lg-end m-0 p-4">
            <b-button
              class="btn ml-2"
              href="#"
              tabindex="0"
              @click.stop="resetRequest"
            >
              <span class="svg-icon">
                <inline-svg
                  src="/media/svg/icons/Neolex/Basic/refresh-cw.svg"
                />
              </span>
              Reset bộ lọc
            </b-button>
            <b-button
              class="btn ml-2"
              href="#"
              tabindex="0"
              @click="searchRequest"
            >
              <span class="svg-icon">
                <inline-svg src="/media/svg/icons/Neolex/Basic/filter.svg" />
              </span>
              Lọc dữ liệu
            </b-button>
          </div>
        </b-dropdown>
        <b-button
          v-if="isWritePermission"
          class="btn btn-success ml-2"
          type="button"
          @click="handleCreate"
        >
          <span class="svg-icon">
            <inline-svg src="/media/svg/icons/Neolex/Basic/plus.svg" />
          </span>
          Thêm cảm xúc
        </b-button>
      </template>
    </basic-subheader>
    <Wrapper>
      <b-overlay :show="loading">
        <v-data-table
          :headers="headers"
          :items="data"
          item-key="id"
          :options.sync="options"
          group-by="emotionType"
          class="systems-parameter"
          :items-per-page="-1"
          :server-items-length="paging.total"
          hide-default-footer
        >
          <template v-slot:header.feeling="{ header }">
            <v-icon @click.stop="expandAll"
              >mdi-{{
                isExpandedNew
                  ? 'unfold-more-horizontal'
                  : 'unfold-less-horizontal'
              }}
            </v-icon>
            {{ header.text }}
          </template>

          <template v-slot:group.header="{ toggle, group, isOpen }">
            <td colspan="6" class="pl-0 no-background">
              <v-btn @click="toggle" :ref="group" :data-open="isOpen" icon>
                <v-icon
                  >mdi-{{ isOpen ? 'chevron-down' : 'chevron-up' }}</v-icon
                >
              </v-btn>
              <span class="font-weight-boldest">{{
                group == 0
                  ? 'Cảm xúc'
                  : group == 1
                  ? 'Hoạt động'
                  : 'Triệu chứng'
              }}</span>
            </td>
            <!-- <td colspan=""></td> -->
          </template>
          <template v-slot:item.feeling="{ item }">
            {{ item.icon }}
          </template>
          <template v-slot:item="{ item, expand, isExpanded }">
            <tr>
              <td>{{ item.code }}</td>
              <td>
                <p class="d-flex align-items-center">
                  <span class="d-flex align-items-center"
                    ><span
                      class="d-flex justify-content-center align-items-center feeling-icon"
                      ><img
                        :src="item.icon.url ? item.icon.url : placeholder"
                        alt=""
                        onerror="javascript:this.src='media/placeholder/placeholder-image.png'"
                        class="feeling-icon"
                    /></span>
                    <span class="ml-4"> {{ item.name }}</span>
                  </span>
                </p>
              </td>
              <td>{{ item.description }}</td>
              <td>
                <a
                  v-if="isWritePermission"
                  class="btn btn-icon btn-sm"
                  @click="handleUpdate(item)"
                  style="
                    background-color: #e6f2f8;
                    border: none;
                    color: #407bff;
                  "
                  title="Chỉnh sửa"
                >
                  <span class="menu-icon svg-icon svg-icon-sm icon-edit">
                    <inline-svg
                      class="svg-icon"
                      src="/media/svg/icons/Neolex/Basic/edit-2.svg"
                    />
                  </span>
                </a>
              </td>
            </tr>
          </template>
        </v-data-table>
      </b-overlay>
      <feeling-modal
        :popupType="popupType"
        :id="id"
        @loadData="loadData"
        :emotionType="emotionType"
      />
    </Wrapper>
  </div>
</template>

<script>
export default {
  name: 'Feeling',
  components: { 'feeling-modal': () => import('./components/Modal') },
  data() {
    return {
      id: null,
      loading: false,
      isExpandedNew: true,
      isExpandedOld: true,
      popupType: 'detail',
      emotionType: null,
      optionTypeEmotions: [
        {
          name: 'Cảm xúc',
          value: 0,
        },
        {
          name: 'Hoạt động',
          value: 1,
        },
        {
          name: 'Triệu chứng',
          value: 2,
        },
      ],
      options: {},
      paging: {
        page: 1,
        pageSize: 999,
        total: 0,
      },
      filter: {
        isActive: null,
        name: null,
        code: null,
        emotionCategoryId: null,
      },
      sort: {
        by: null,
        order: null,
      },
      headers: [
        {
          text: 'Mã',
          align: 'center',
          value: 'code',
          width: '10%',
          sortable: true,
        },
        {
          text: 'Cảm xúc',
          value: 'name',
          sortable: true,
          align: 'left',
          width: '25%',
        },
        { text: 'Ghi chú', value: 'note', sortable: false, width: '55%' },
        { text: '', value: '', sortable: false, width: '10%' },
      ],

      data: [],
    };
  },
  watch: {
    options: {
      handler(newVal) {
        let { sortBy, sortDesc } = newVal;
        let [order] = sortDesc;
        let [sort] = sortBy;
        this.sort.by = sort;

        this.sort.order = order ? 'desc' : 'asc';
      },
      deep: true,
    },
    paging: {
      handler() {
        this.loadData();
      },
      deep: true,
    },
    sort: {
      handler() {
        this.loadData();
      },
      deep: true,
    },
  },

  computed: {
    placeholder() {
      return require('../../../../public/media/placeholder/placeholder-image.png');
    },

    searchParams() {
      return {
        orderBy: this.sort.by ? `${this.sort.by} ${this.sort.order}` : null,
        page: this.paging.page,
        size: this.paging.pageSize,
        excludeInactive: this.filter.isActive?.[0] == true ? true : false,
        code: this.filter.code,
        name: this.filter.name,
        emotionType: this.filter.emotionCategoryId,
      };
    },

    isWritePermission() {
      return this.$route.meta.isWritePermission;
    },
  },
  methods: {
    pagingAction() {
      this.loadData();
    },
    searchRequest() {
      this.loadData();
      document.body.click();
    },
    resetRequest() {
      this.filter.isActive = [];
      this.filter.name = null;
      this.filter.code = null;
      this.filter.emotionCategoryId = null;
      this.filter.status = null;
      this.loadData();
      document.body.click();
    },
    sortRequest(sortData) {
      this.sort = {
        by: sortData.column,
        order: sortData.order,
      };
      return;
    },
    viewItem() {},
    editItem() {},
    deleteItem() {},
    loadData() {
      this.loading = true;
      this.$api
        .get('Admin/Emotion', { params: this.searchParams, ...this.sort })
        .then(({ meta, data }) => {
          this.data = data;
          this.paging.total = meta.total;
        })
        .catch((error) => {
          this.$toastr.e({
            title: 'Lỗi!',
            msg: error,
          });
        })
        .finally(() => {
          this.loading = false;
        });
      return;
    },
    async handleUpdate(item) {
      this.popupType = 'detail';
      this.id = item.id;
      this.emotionType = item.emotionType;
      this.$nextTick(() => {
        this.$bvModal.show('feeling-modal');
        this.id = null;
      });
    },
    handleCreate() {
      this.popupType = 'create';
      this.$bvModal.show('feeling-modal');
    },
    expandAll() {
      this.isExpandedNew = !this.isExpandedOld;
      // Filter vue-components
      Object.keys(this.$refs)
        .filter((e) => this.$refs[e]?.$el)
        .forEach((k) => {
          // Check if element has already open
          const status = this.$refs[k].$attrs['data-open'];
          if (status != this.isExpandedNew) {
            this.$refs[k].$el.click();
          }
          return;
        });
      this.isExpandedOld = this.isExpandedNew;
    },
  },
};
</script>

<style lang="scss" scoped>
.icon-edit {
  background-color: #e6f2f8;
  border: none;
  color: #407bff;
}
.mw-400 {
  min-width: 400px;
}
.feeling-icon {
  min-width: 30px;
  font-size: 1.6rem;
  border-radius: 50%;
  border: dashed 2px #f2c94c;
  width: 30px;
  height: 30px;
  background-color: rgba(242, 201, 76, 0.2);
}
</style>
<style lang="scss">
.v-data-table-header {
  th:first-child {
    padding-left: 0 !important;
  }
}
</style>
